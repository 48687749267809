.auth-page-content{
  margin-top: 175px;
}

.filepond--drop-label {
  background-color: #ECF7E9;
}

.PhoneInput input {
  border: none !important;
}

.select-region__control {
  border-radius: 0; /* Remove rounded corners */
}